export function useMonitorings() {
  const monitorings = [
    {
      title: "Мониторинг обменных пунктов BestChange",
      link: "https://www.bestchange.ru/kotleta-exchanger.html",
      image: "/images/common/bestchange.svg",
    },
    {
      title: "Мониторинг обменников Exchange Sumo",
      link: "https://exchangesumo.com/exchanger/1174/Kotleta/",
      image: "/images/common/exchange-sumo.svg",
    },
    {
      title: "Мониторинг обменников Exnode",
      link: "https://exnode.ru/exchangers/exchanger-4966905",
      image: "/images/common/exnode.svg",
    },
    {
      title: "Bits.media",
      link: "https://bits.media/exchanger/kotleta/",
      image: "/images/common/bits-media.png",
    },
    {
      title: "Okchanger",
      link: "https://www.okchanger.com",
      image: "/images/common/okchanger.png",
    },
    {
      title: "Мониторинг обменных пунктов GLAZOK",
      link: "https://glazok.org/exchange/?details=1316",
      image: "/images/common/glazok.gif",
    },
  ];

  return monitorings;
}
